import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { twMerge } from 'tailwind-merge';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { NewTag, SfxTag, StaffPickTag } from '@videoblocks/storywind';

import { WaveformDataInterface } from '../../../../Audio/AudioTypes';
import {
  getDownloadPrimaryClickAction,
  getPlayedClips,
  logAudioPlay,
} from '../../../../Audio/AudioUtils';
import {
  setAudioFiltersChanged,
  setPendingCurrentTime,
  setPendingPlayAudio,
} from '../../../../Audio/actions/AudioActions';
import {
  selectAudioSourceUrl,
  selectCurrentTime,
  selectDuration,
  selectIsPaused,
  selectIsPlaying,
} from '../../../../Audio/selectors/audioSelectors';
import { selectDetailsAbTests } from '../../../../Details/selectors/detailsSelectors';
import displayEventMediator from '../../../../Events/DisplayEventMediator';
import StockItemDisplayEventFactory from '../../../../Events/StockItemDisplayEventFactory';
import { selectMemberBins } from '../../../../Member/MemberBins/selectors/foldersSelectors';
import {
  selectHasAudioSubscription,
  selectIsLoggedIn,
  selectPrimarySubscription,
} from '../../../../auth/AuthSelectors';
import { Subscription } from '../../../../auth/AuthTypes';
import ElementDisplayedListener from '../../../../common/ElementDisplayedListener';
import AudioSiteConstants from '../../../../common/SiteConstants/AudioSiteConstants';
import AudioPlayerArtists from '../../../../common/components/AudioPlayer/AudioPlayerArtists';
import AudioPlayerDuration from '../../../../common/components/AudioPlayer/AudioPlayerDuration';
import AudioPlayerFavoriteButton from '../../../../common/components/AudioPlayer/AudioPlayerFavoriteButton';
import AudioPlayerPlayButton from '../../../../common/components/AudioPlayer/AudioPlayerPlayButton';
import AudioPlayerTitle from '../../../../common/components/AudioPlayer/AudioPlayerTitle';
import AudioPlayerWaveform from '../../../../common/components/AudioPlayer/AudioPlayerWaveform';
import SearchSimilarPopover from '../../../../common/components/Popover/SearchSimilarPopover';
import SignUpForMakerPopover from '../../../../common/components/Popover/SignUpForMakerPopover';
import Popper, {
  Placements,
} from '../../../../common/components/Popper/Popper';
import { StockDownloadContext } from '../../../../common/context/StockDownloadContext';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../../common/types/StockItemTypes';
import { getSliderValue } from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import { DrawerType } from '../../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  expandMoreLikeThisDrawer,
  fetchCollapsedSetResults,
  toggleStockItemSelect,
  updateSearchOptionsAndRefresh,
  updateSearchPage,
} from '../../actions/SearchActions';
import {
  AudioSelectedSearchFilterOptions,
  StockItemArtistInterface,
} from '../../containers/MenuContainerInterfaces';
import SearchOptions from '../../entities/SearchOptions';
import {
  selectCollapsedSetStockItems,
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectDrawerType,
  selectSearchAbTests,
  selectSearchFilterOptions,
  selectSelectedStockItem,
} from '../../selectors/searchSelectors';
import AudioDownloadButton from '../AudioDownloadButton/AudioDownloadButton';

import './AudioStockItemCard.less';

type Props = {
  stockItem: StockItem;
  stockItemFormats: StockItemFormat[];
  isSelected: boolean;
  searchLogParameters: object;
  style: object;
  classNames: string[];
  shouldShowAddToFavorites: boolean;
  page: number;
  favoritesBinId: number;
  context: StockItemContext;
  stockItemCardContext?: { title: string; position: number };
  draggableCard: boolean;
  dropOntoHandler: () => void;
  downloadButtonTelemetryAction: () => void;
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  topTags?: Array<{
    tag_name: string;
    tag_url_id: string;
    tag_id: string;
    tag_type: string;
  }>;
  shouldShowArtists: boolean;
  stockItemArtists: StockItemArtistInterface[];
  genres: object[];
  moods: object[];
  shouldShowSearchSimilar: boolean;
  makerButtonTelemetryAction: () => void;
  topSectionDivClassNames: string;
  bottomSectionDivClassNames: string;
  audioPlayerHeaderClassName: string;
  sectionClassName: string;
  isForCrossAssetMusicRecommendation: boolean;
  metaDataClassNames: string;
  artistClassNames: string;
  tagClassNames: string;
  checked: boolean;
  updateSearchPage: typeof updateSearchPage;
  setPendingPlayAudio: typeof setPendingPlayAudio;
  toggleStockItemSelect: typeof toggleStockItemSelect;
  audioSubscription: Subscription;
  primarySubscription: Subscription;
  shouldShowDownloadButton?: boolean;
  dispatch: ThunkDispatch<any, any, any>;
  drawerType?: DrawerType;
  isLoggedIn: boolean;
  isPlaying: boolean;
  isPaused: boolean;
  hasBeenPlayed: boolean;
  drawerIsOpen: boolean;
  drawerSelectedItemId: number;
  isMobile: boolean;
  currentTime: number;
  formatSelectorVisible?: boolean;
  currentSource: string;
  openDetailsInNewWindow?: boolean;
};

type State = {
  controller: AbortController;
  hasBeenPlayed: boolean;
  showDownloadOptions: boolean;
  showAltVersionsTooltip: boolean;
  waveformData: WaveformDataInterface;
};

class AudioStockItemCard extends Component<Props, State> {
  containerRef: React.RefObject<HTMLElement>;

  constructor(props) {
    super(props);

    this.state = {
      controller: null,
      hasBeenPlayed: false,
      showDownloadOptions: false,
      showAltVersionsTooltip: false,
      waveformData: null,
    };

    this.containerRef = React.createRef();
  }

  static defaultProps = {
    draggableCard: false,
    hasBeenPlayed: false,
    shouldShowDownloadButton: true,
    downloadButtonTelemetryAction: null,
    shouldShowArtists: true,
    shouldShowSearchSimilar: false,
    makerButtonTelemetryAction: null,
    stockItemCardContext: {},
    openDetailsInNewWindow: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState !== this.state ||
      this.isCurrentSource() ||
      this.props.checked !== nextProps.checked ||
      this.props.draggableCard !== nextProps.draggableCard
    );
  }

  componentDidMount() {
    this.setState({
      hasBeenPlayed: this.getHasClipBeenPlayed(),
      controller: new AbortController(),
    });

    ElementDisplayedListener(this.containerRef.current, () => {
      const context = this.props.stockItemCardContext;

      if (context.position) {
        displayEventMediator.push(
          StockItemDisplayEventFactory.displayedStockItem(
            this.props.stockItem,
            context.position
          )
        );
      }
    });

    ElementDisplayedListener(
      this.containerRef.current,
      // fetch waveform data as soon as container enters viewport (low threshold)
      () => this.fetchWaveformData(),
      0.1
    );
  }

  componentWillUnmount() {
    this.state.controller.abort();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasBeenPlayed !== this.getHasClipBeenPlayed()) {
      this.setState({
        hasBeenPlayed: true,
      });
    }
  }

  async fetchWaveformData() {
    const { waveformUrl } = this.props.stockItem;

    try {
      const thumbnailResponse = await fetch(waveformUrl, {
        signal: this.state.controller.signal,
      });
      const thumbnailData = await thumbnailResponse.json();

      this.setState({
        waveformData: thumbnailData,
      });
    } catch (ignored) {}
  }

  handleIsHovering = () => {
    if (!isNaN(this.props.page)) {
      this.props.updateSearchPage(this.props.page, this.props.stockItem.id);
    }
  };

  render() {
    const {
      audioSubscription,
      primarySubscription,
      shouldShowAddToFavorites,
      shouldShowDownloadButton,
      stockItem,
      stockItemFormats,
      style,
      context,
      searchLogParameters,
      dispatch,
      downloadButtonTelemetryAction,
      drawerType,
    } = this.props;

    const { numCollapses } = stockItem;

    const durationClassNames = twMerge(
      'order-1',
      !this.isCrossAssetContext() && 'lg:order-2',
      numCollapses < 2 && !this.isCrossAssetContext() && 'lg:ml-15'
    );

    const buttonsWrapperClassNames = twMerge(
      'order-3 flex items-center space-x-4',
      !this.isCrossAssetContext() && 'lg:space-x-6'
    );

    const displaySearchSimilar =
      (stockItem.hasVector && !this.isInRecommendedMusicDrawer()) ||
      this.isCrossAssetContext();

    const fireDownloadTelemetryActionFromParent = () => {
      if (downloadButtonTelemetryAction) downloadButtonTelemetryAction();
    };

    const downloadUrlParams: Record<string, string> = this.context; // StockDownloadContext

    return (
      <section
        className={this.getContainerClassNames()}
        ref={this.containerRef}
        data-stock-id={stockItem.id}
        onMouseEnter={this.handleIsHovering}
        style={style}
      >
        <div
          className={twMerge(
            `left-container flex w-full py-2`,
            !this.isCrossAssetContext() && 'lg:w-1/3 lg:py-0',
            this.props.topSectionDivClassNames
          )}
        >
          {this.renderPlayButton()}
          {this.renderMetadata()}
        </div>

        <div
          className={twMerge(
            `flex w-full h-16 px-4 py-2 gap-x-4`,
            !this.isCrossAssetContext() &&
              'lg:w-2/3 lg:h-auto lg:px-8 lg:py-4 lg:gap-x-6',
            this.props.bottomSectionDivClassNames
          )}
        >
          {this.renderAltVersionsButton(stockItem)}

          <AudioPlayerWaveform
            waveformData={this.state.waveformData}
            stockItem={stockItem}
            currentTime={this.props.currentTime}
            isCurrentSource={this.isCurrentSource()}
            handleProgressClicked={this.handleProgressClicked}
            className={twMerge(
              'order-2',
              !this.isCrossAssetContext() && 'lg:order-1'
            )}
          />

          <AudioPlayerDuration
            duration={stockItem.duration}
            className={durationClassNames}
          />

          {(shouldShowAddToFavorites || shouldShowDownloadButton) && (
            <div className={buttonsWrapperClassNames}>
              {this.shouldShowMakerButton() && this.renderMakerButton()}
              {displaySearchSimilar && this.renderSearchSimilar()}
              {shouldShowAddToFavorites && this.renderFavoriteButton()}
              {shouldShowDownloadButton && (
                <AudioDownloadButton
                  onDownloadOptionClick={(event) =>
                    getDownloadPrimaryClickAction(
                      event,
                      stockItem,
                      stockItemFormats,
                      audioSubscription,
                      primarySubscription,
                      searchLogParameters,
                      context,
                      drawerType,
                      dispatch,
                      downloadUrlParams
                    )
                  }
                  stockItem={this.props.stockItem}
                  stockItemFormats={this.props.stockItemFormats}
                  iconClassName={twMerge(
                    'text-black',
                    this.isCrossAssetContext() ? 'w-4 h-4' : 'w-6 h-6'
                  )}
                  onDownloadButtonClick={() =>
                    fireDownloadTelemetryActionFromParent()
                  }
                />
              )}
            </div>
          )}
        </div>
      </section>
    );
  }

  getContainerClassNames() {
    const { context, formatSelectorVisible, isPaused, isPlaying, isSelected } =
      this.props;

    const isDetailsPlayer = context === StockItemContext.DETAILS_PLAYER;
    const isInDrawer = context === StockItemContext.DRAWER;
    const isInRecommendedMusicDrawer = this.isInRecommendedMusicDrawer();
    const played =
      !isDetailsPlayer &&
      !isInRecommendedMusicDrawer &&
      this.state.hasBeenPlayed;

    return twMerge(
      'stock-item audio flex w-full m-0 p-0 z-auto flex-wrap text-black relative',
      'border-0 border-b first:border-t border-solid border-gray-300',
      isDetailsPlayer ? 'border-l border-r rounded-lg' : '',
      !this.isCrossAssetContext() && 'lg:h-24 lg:flex-nowrap',
      played ? 'played' : 'bg-white',
      formatSelectorVisible && 'download-format-selector-visible',
      isDetailsPlayer && 'details-player',
      isSelected && 'is-selected',
      this.isCurrentSource() && isPaused && 'paused',
      this.isCurrentSource() && isPlaying && 'playing',
      isInDrawer && 'collapsed-content',
      this.props.classNames
    );
  }

  renderPlayButton = () => {
    const {
      stockItem,
      stockItemArtists,
      stockItemFormats,
      context,
      drawerType,
    } = this.props;

    const className = twMerge(
      'p-4',
      this.isInAltVersionsDrawer() && 'ml-8',
      this.isInRecommendedMusicDrawer() && '-ml-4'
    );

    return (
      <AudioPlayerPlayButton
        stockItem={stockItem}
        playHandler={this.logPlay}
        stockItemArtists={stockItemArtists}
        stockItemFormats={stockItemFormats}
        waveformData={this.state.waveformData}
        className={className}
        wrapperClassName={twMerge(
          'bg-gray-250 w-11.5 h-11.5',
          this.isInAltVersionsDrawer()
            ? 'lg:w-12 lg:h-12'
            : !this.isCrossAssetContext() && 'lg:w-13 lg:h-13'
        )}
        iconClassName={`${this.isInAltVersionsDrawer() ? 'w-4' : 'w-5'}`}
        context={context}
        drawerType={drawerType}
      />
    );
  };

  logPlay = () => {
    const { stockItem, isLoggedIn } = this.props;

    logAudioPlay(stockItem, isLoggedIn);

    this.setState({
      hasBeenPlayed: true,
    });
  };

  renderMetadata() {
    const { context, stockItem } = this.props;
    const { title, detailsUrl } = stockItem;

    return (
      <>
        <div
          className={twMerge(
            'stock-item-metadata flex flex-col items-start justify-center',
            this.props.metaDataClassNames
          )}
        >
          <div className={`flex items-center mb-0.5`}>
            {stockItem.shouldStockItemHaveStaffPickFlag && <StaffPickTag />}
            {stockItem.shouldStockItemHaveNewFlag && <NewTag />}
            <AudioPlayerTitle
              title={title}
              detailsUrl={detailsUrl}
              context={this.props.context}
              isCurrentSource={this.isCurrentSource()}
              className={twMerge(
                'text-black hover:text-blue-500',
                context === StockItemContext.DRAWER && 'font-semibold'
              )}
              headerClassName={this.props.audioPlayerHeaderClassName}
              openDetailsInNewWindow={this.props.openDetailsInNewWindow}
              stockItem={this.props.stockItem}
              stockItemCardContext={this.props.stockItemCardContext}
            />
          </div>
          {this.renderArtists()}
          {this.renderTags()}
        </div>
      </>
    );
  }

  renderArtists() {
    const { shouldShowArtists, stockItem, stockItemArtists, context } =
      this.props;

    if (!shouldShowArtists) {
      return null;
    }

    return (
      <AudioPlayerArtists
        stockItemArtists={stockItemArtists}
        stockItem={stockItem}
        context={context}
        className={twMerge(
          'text-sm text-gray-700 hover:text-blue-500',
          this.props.artistClassNames
        )}
        wrapperClassName="text-gray-700"
      />
    );
  }

  renderTags() {
    return (
      <div className="tags flex w-full space-x-2">
        {this.renderContentTypeTag()}
        {this.renderTopTags()}
      </div>
    );
  }

  renderContentTypeTag() {
    const contentType = this.props.stockItem.type;
    if (contentType !== 'sfx') {
      return null;
    }

    return <SfxTag />;
  }

  sortByTagPosition(tagOne, tagTwo) {
    return parseFloat(tagOne.tag_position) - parseFloat(tagTwo.tag_position);
  }

  renderTopTags() {
    const { stockItem, topTags } = this.props;

    if (!topTags) {
      return null;
    }

    // sometimes the stockItem object provided to the AudioStockItemCard is frozen or read-only, which
    // was causing array sort to throw an error. This sorts safely without error. - Clark - 2022-11-07
    const sortedTags = [...topTags].sort((itemOne, itemTwo) =>
      this.sortByTagPosition(itemOne, itemTwo)
    );

    const { contentType } = stockItem;

    return (
      <div
        className={twMerge(
          'top-tags flex flex-wrap w-full h-5 overflow-hidden text-sm leading-5 text-blue-500',
          this.props.tagClassNames
        )}
      >
        {sortedTags
          .filter((tag) => tag.tag_name)
          .map((tag) => {
            const { tag_name, tag_url_id, tag_id, tag_type } = tag;

            const url = `${new AudioSiteConstants().getSearchRoute()}?media-type=${contentType}&categories=${tag_url_id}`;

            return (
              <span className="tag-wrapper" key={tag_name}>
                <a
                  className="hover:underline"
                  href={url}
                  onClick={(e) => this.onTopTagClick(e, tag_id, tag_type, url)}
                  target={
                    this.props.openDetailsInNewWindow ? '_blank' : '_self'
                  }
                >
                  {tag_name}
                </a>
              </span>
            );
          })}
      </div>
    );
  }

  onTopTagClick(e, tagId, tagType, url) {
    e.preventDefault();

    if (this.props.context !== 'search') {
      if (this.props.openDetailsInNewWindow) {
        window.open(`${url}&search-origin=${searchOrigins.STOCK_ITEM_CARD}`);
      } else {
        window.location.href = `${url}&search-origin=${searchOrigins.STOCK_ITEM_CARD}`;
      }
      return;
    }

    const categoriesArrayName =
      this.getSelectedCategoriesArrayNameByType(tagType);

    if (
      this.props.selectedSearchFilterOptions[categoriesArrayName].includes(
        tagId
      )
    ) {
      return;
    }

    const newSearchOptions = SearchOptions.getDefaultSearchOptions().update({
      contentType: this.getSelectedTagTypeName(tagType),
      searchOrigin: searchOrigins.STOCK_ITEM_CARD,
      [categoriesArrayName]: [tagId],
    });

    this.props.dispatch(setAudioFiltersChanged(true));
    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  }

  getSelectedCategoriesArrayNameByType(type) {
    switch (type) {
      case 1:
        return 'musicMoods';
      case 2:
        return 'musicGenres';
      case 3:
        return 'musicInstruments';
      case 4:
        return 'sfxCategories';
      default:
        return null;
    }
  }

  getSelectedTagTypeName(typeId) {
    switch (typeId) {
      case 1:
      case 2:
      case 3:
        return 'music';
      case 4:
        return 'sfx';
      default:
        return null;
    }
  }

  getHasClipBeenPlayed() {
    if (window.localStorage) {
      const playedClips = getPlayedClips();
      const stockItemIds = playedClips['stockItemIds'] || [];
      return (
        this.props.hasBeenPlayed ||
        stockItemIds.includes(this.props.stockItem.id)
      );
    } else {
      return false;
    }
  }

  renderAltVersionsButton(stockItem) {
    const { context, drawerIsOpen, drawerSelectedItemId } = this.props;
    const { collapsedSetId, numCollapses } = stockItem;

    if (
      context !== StockItemContext.SEARCH ||
      !collapsedSetId ||
      numCollapses < 2
    ) {
      return;
    }

    const numberOfCollapses = numCollapses > 10 ? 10 : numCollapses - 1;

    return (
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={this.state.showAltVersionsTooltip}
        triggerElement={(referenceElement) => (
          <div
            className="lg:flex items-center hidden"
            ref={referenceElement}
            onMouseEnter={() => this.setState({ showAltVersionsTooltip: true })}
            onMouseLeave={() =>
              this.setState({ showAltVersionsTooltip: false })
            }
          >
            <a
              href="#"
              className={`alt-versions h-7 w-7 font-bold bg-gray-700 text-white rounded-full text-sm lg:flex justify-center items-center ${
                drawerIsOpen && stockItem.id === drawerSelectedItemId
                  ? 'expanded'
                  : ''
              }`}
              onClick={(e) => this.toggleAltVersions(e)}
            >
              +{numberOfCollapses}
            </a>
          </div>
        )}
      >
        <div id="tooltip-alt-versions">More Versions</div>
      </Popper>
    );
  }

  toggleAltVersions(e) {
    e.preventDefault();

    const {
      dispatch,
      drawerIsOpen,
      drawerSelectedItemId,
      selectedSearchFilterOptions,
      stockItem,
    } = this.props;

    if (drawerIsOpen && drawerSelectedItemId === stockItem.id) {
      dispatch(collapseMoreLikeThisDrawer());
    } else {
      Telemetry.increment('search.click.altVersions');

      const { collapsedSetId, id } = stockItem;
      const updateFields = {
        collapsedSetId,
        id,
        excludeStockItemIds: id,
        page: 1,
      };
      const newSearchOptions = selectedSearchFilterOptions.update(updateFields);
      dispatch(fetchCollapsedSetResults(newSearchOptions));
      dispatch(
        expandMoreLikeThisDrawer({
          initialItemId: id,
          drawerType: DrawerType.ALT_VERSIONS,
        })
      );
    }
  }

  shouldShowMakerButton() {
    const { isMobile, context } = this.props;
    return (
      (!isMobile && !this.isInRecommendedMusicDrawer()) ||
      context === StockItemContext.CROSS_ASSET_COLLECTION
    );
  }

  renderMakerButton() {
    const { stockItem, makerButtonTelemetryAction } = this.props;

    return (
      <SignUpForMakerPopover
        mediaType="audio"
        stockItemId={stockItem.id}
        iconClassName={twMerge(
          'fill-current text-black',
          this.isCrossAssetContext() ? 'w-4 h-4' : 'w-6 h-5'
        )}
        onClickAction={() => makerButtonTelemetryAction?.()}
      />
    );
  }

  renderFavoriteButton = () => {
    const { stockItem, stockItemFormats } = this.props;

    return (
      <AudioPlayerFavoriteButton
        stockItem={stockItem}
        stockItemFormats={stockItemFormats}
        iconClassName={this.isCrossAssetContext() ? 'w-4 h-4' : 'w-6 h-6'}
        telemetryKey={
          this.isInRecommendedMusicDrawer() ? 'recommended_music' : 'search'
        }
      />
    );
  };

  renderSearchSimilar() {
    const { stockItem, selectedSearchFilterOptions, openDetailsInNewWindow } =
      this.props;
    return (
      <SearchSimilarPopover
        stockItemId={stockItem.id}
        stockItemTitle={stockItem.title}
        selectedContentType={selectedSearchFilterOptions.contentType}
        iconClassName={this.isCrossAssetContext() ? 'w-4 h-4' : 'w-6 h-6'}
        openDetailsInNewWindow={openDetailsInNewWindow}
      />
    );
  }

  handleProgressClicked = (event) => {
    const {
      dispatch,
      isPlaying,
      stockItem,
      stockItemArtists,
      stockItemFormats,
    } = this.props;
    const percentage = getSliderValue(event);
    const position = this.props.stockItem.duration * percentage;
    const waveformData = this.state.waveformData;

    if ((this.isCurrentSource() && !isPlaying) || !this.isCurrentSource()) {
      this.logPlay();
      dispatch(
        this.props.setPendingPlayAudio(
          stockItem.previewUrl,
          position,
          stockItem,
          stockItemArtists,
          stockItemFormats,
          waveformData
        )
      );
    } else {
      dispatch(setPendingCurrentTime(position, stockItem));
    }
  };

  onSelectCheckboxClick = () => {
    this.props.toggleStockItemSelect(this.props);
  };

  isCurrentSource() {
    return this.props.stockItem.previewUrl === this.props.currentSource;
  }

  isInRecommendedMusicDrawer() {
    const { context, drawerType } = this.props;
    return (
      context === StockItemContext.DRAWER &&
      drawerType === DrawerType.RECOMMENDED_MUSIC
    );
  }

  isInAltVersionsDrawer() {
    const { context, drawerType } = this.props;
    return (
      context === StockItemContext.DRAWER &&
      drawerType === DrawerType.ALT_VERSIONS
    );
  }

  isCrossAssetContext(): boolean {
    return [
      StockItemContext.CROSS_ASSET_COLLECTION,
      StockItemContext.STORYBOARD,
    ].includes(this.props.context);
  }
}
AudioStockItemCard.contextType = StockDownloadContext;

function mapStateToProps(state, props) {
  const selectedStockItem = selectSelectedStockItem(state);
  const isStockItemSelected = selectedStockItem.id === props.stockItem.id;
  const detailsAbTests = selectDetailsAbTests(state);
  const searchAbTests = selectSearchAbTests(state);

  const shouldShowSearchSimilar =
    // @ts-expect-error {} does not have property
    searchAbTests?.shouldShowSearchSimilar ||
    // @ts-expect-error {} does not have property
    detailsAbTests?.shouldShowSearchSimilar;

  return {
    audioSubscription: selectHasAudioSubscription(state),
    primarySubscription: selectPrimarySubscription(state),
    currentSource: selectAudioSourceUrl(state),
    currentTime: selectCurrentTime(state),
    duration: selectDuration(state),
    isLoggedIn: selectIsLoggedIn(state),
    isPaused: selectIsPaused(state),
    isPlaying: selectIsPlaying(state),
    bins: selectMemberBins(state),
    selectedFormatId: isStockItemSelected
      ? selectedStockItem.selectedFormatId
      : -1,
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    collapsedSetStockItems: selectCollapsedSetStockItems(state),
    drawerIsOpen: selectDrawerIsOpen(state),
    drawerSelectedItemId: selectDrawerSelectedItemId(state),
    drawerType: selectDrawerType(state),
    shouldShowSearchSimilar,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateSearchPage,
  setPendingPlayAudio,
  toggleStockItemSelect,
  dispatch,
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AudioStockItemCard);

import { ReduxState } from '../../AppLoader/types';
import { ContentClass } from '../../app/Shared/enums';

export const selectDetailsSignUpLink = (state: ReduxState) =>
  state.details.features.signUpLink;

export const selectRedirectToSignUpLink = (state: ReduxState) =>
  state.details.features.redirectToSignUpLink;

export const selectDetailsIsEnterpriseMember = (state: ReduxState) =>
  state.details.features.isEnterpriseMember;

export const selectUseSubscriptionlessSignUpEnticement = (state: ReduxState) =>
  state.details.features.useSubscriptionlessSignUpEnticement;

export const selectDetailsStockItem = (state: ReduxState) =>
  state.details.stockItem;

export const selectDetailsStockItemIsContentClass =
  (contentClass: ContentClass) => (state) => {
    const stockItem = selectDetailsStockItem(state);
    return stockItem.contentClass === contentClass;
  };

export const selectDetailsStockItemFormats = (state: ReduxState) =>
  state.details.stockItemFormats;

export const selectDetailsStockItemArtists = (state: ReduxState) =>
  state.details.stockItemArtists;

export const selectDetailsAcquisitionOption = (state: ReduxState) =>
  state.details.selectedAcquisitionOption;

export const selectDetailsDownloadPermissions = (state: ReduxState) =>
  state.details.features.downloadPermissions;

export const selectDetailsMemberDownloadDate = (state: ReduxState) =>
  state.details.memberDownloadDate;

export const selectDetailsCanExportToCloud = (state: ReduxState) =>
  state.details.canExportToCloud;

export const selectDetailsCloudExportSubfolders = (state: ReduxState) =>
  state.details.cloudExportSubfolders || [];

export const selectDetailsCloudExportStatus = (state: ReduxState) =>
  state.details.cloudExportStatus;

export const selectDetailsCloudExportSubfolderStatus = (state: ReduxState) =>
  state.details.cloudExportSubfolderStatus;

export const selectDetailsCanDownload = (state: ReduxState) =>
  state?.details?.canDownload === true;

export const selectDetailsShouldShowAddToFavorites = (state: ReduxState) =>
  state.details.features.shouldShowAddToFavorites;

export const selectCanDownloadPreviews = (state: ReduxState) =>
  state.details.features.canDownloadPreviews;

export const selectDetailsMakerPreCreateProjectRoute = (state: ReduxState) =>
  state.details.makerHooks.makerPreCreateProjectRoute;

export const selectDetailsMakerSignupRoute = (state: ReduxState) =>
  state.details.makerHooks.makerSignupRoute;

export const selectSimilarStockItems = (state: ReduxState) =>
  state.details.similarStockItems;

export const selectMvtPages = (state: ReduxState) => state.details.mvtPages;

export const selectCanDownloadNonWatermarkedComps = (state: ReduxState) =>
  state.details.member.canDownloadNonWatermarkedComps;

export const selectMusicRecommendations = (state: ReduxState) =>
  state.details.musicRecommendations;

export const selectDetailsAbTests = (state: ReduxState) =>
  state.details.features.abTests;

export const selectDetailsFeatures = (state: ReduxState) =>
  state.details.features;

export const selectUseSameShootSameModelDetails = (state: ReduxState) =>
  state.details.features.useSameShootSameModelDetails;

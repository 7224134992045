import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Add } from '@videoblocks/react-icons';

import { Folder } from '../../Member/Folders/types';
import {
  selectIsInSharedFoldersBeta,
  selectIsLoadingFolders,
  selectMemberBins,
} from '../../Member/MemberBins/selectors/foldersSelectors';
import SiteConstants from '../../common/SiteConstants/SiteConstants';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import { StockItem } from '../../common/types/StockItemTypes';
import FolderCard from './FolderCard';

interface Props {
  folderClickedCallback: (p: { bin: Folder; event: Event }) => void;
  createNewClickedCallback: (p: { name: string; event: Event }) => void;
  showAllButton?: boolean;
  shouldShowYourFoldersFilter?: boolean;
  shouldShowYourFoldersOnly?: boolean;
  stockItem?: StockItem;
  title?: string;
  openFoldersInNewWindow?: boolean;
}

const FolderSelector = ({
  folderClickedCallback,
  createNewClickedCallback,
  showAllButton = true,
  stockItem = null,
  shouldShowYourFoldersFilter = false,
  shouldShowYourFoldersOnly = false,
  title,
  openFoldersInNewWindow = false,
}: Props) => {
  const bins = useSelector(selectMemberBins);
  const isInSharedFoldersBeta = useSelector(selectIsInSharedFoldersBeta);
  const isLoading = useSelector(selectIsLoadingFolders);

  const [matchingBins, setMatchingBins] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showOnlyOwnedFolders, setShowOnlyOwnedFolders] = useState(
    shouldShowYourFoldersOnly
  );

  const popoverContentRef = useRef();
  const searchInputRef = useRef<HTMLInputElement>();

  const containsSearchTerm = (bin) => {
    const searchTerm = searchText.trim().toLowerCase();

    return (
      bin.name.toLowerCase().includes(searchTerm) ||
      (searchTerm === 'me' && bin.isOwnedByMe)
    );
  };

  useEffect(() => {
    if (!isLoading) {
      searchInputRef?.current?.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    const searchResults =
      searchText === ''
        ? Object.values(bins)
        : Object.values(bins)
            .filter((bin) => containsSearchTerm(bin))
            .filter((bin) => (isInSharedFoldersBeta ? bin.isOwnedByMe : true));

    setMatchingBins(
      searchResults.sort(
        (a, b) => +new Date(b.dateUpdated) - +new Date(a.dateUpdated)
      )
    );
  }, [bins, searchText, showOnlyOwnedFolders]);

  const selectFolder = (event, bin) => {
    folderClickedCallback({ event, bin });
    setSearchText('');
  };

  const createNewFolder = (event) => {
    createNewClickedCallback({ event, name: searchText });
    setSearchText('');
  };

  return (
    <div
      id="member-bin-popover"
      className="foldersPopover w-64 text-black"
      ref={popoverContentRef}
    >
      <h3 className="px-4 py-2 m-0 text-base font-bold bg-gray-100 border-0 border-b border-gray-400 border-solid">
        {title}
      </h3>

      <div className={`p-4 pb-2 ${isLoading ? 'h-80' : ''}`}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <input
              aria-label="Input new folder"
              className="search-textbox w-full px-3 py-2 mb-4 overflow-x-scroll border border-gray-400 border-solid"
              onChange={({ target }) => setSearchText(target.value)}
              placeholder="Find or Create New Folder"
              ref={searchInputRef}
              type="text"
              value={searchText}
            />

            {shouldShowYourFoldersFilter && (
              <label className="flex-nowrap flex items-center flex-grow m-0 text-base font-normal">
                <input
                  checked={showOnlyOwnedFolders}
                  onChange={({ target }) =>
                    setShowOnlyOwnedFolders(target.checked)
                  }
                  type="checkbox"
                />
                <span className="ml-2">Show my folders only.</span>
              </label>
            )}

            <div className="flex-nowrap h-60 flex flex-col mb-3 overflow-y-scroll">
              {matchingBins.length < 1 && (
                <div
                  role="button"
                  tabIndex={0}
                  id="new-folder-button"
                  className="hover:bg-gray-200 flex-nowrap flex w-full mb-3 cursor-pointer"
                  onClick={createNewFolder}
                  onKeyDown={createNewFolder}
                >
                  <div className="add-new-bin-button inline-flex items-center justify-center w-12 h-12 text-xl bg-yellow-500">
                    <Add className="w-4 h-4" />
                  </div>
                  <div className="flex-grow inline-block ml-3 text-left break-all">
                    '{searchText}'
                  </div>
                </div>
              )}

              {matchingBins.map((bin, index) => {
                const existsInBin = stockItem
                  ? bin.contentIds.includes(stockItem.contentId)
                  : false;

                return (
                  <FolderCard
                    bin={bin}
                    existsInBin={existsInBin}
                    handleBinClick={(e, bin) => selectFolder(e, bin)}
                    key={`member-bin-popover-card-${index}`}
                    isShared={isInSharedFoldersBeta && bin.isShared}
                  />
                );
              })}

              {showAllButton && (
                <div className="w-full pt-4 pb-1 m-auto mb-0 text-sm font-bold text-center border-0 border-t border-gray-400 border-solid">
                  <a
                    className="hover:text-blue-600 m-0 text-blue-500 no-underline"
                    href={SiteConstants.buildInstance().getFoldersBasePath()}
                    target={openFoldersInNewWindow ? '_blank' : '_self'}
                  >
                    View All Folders →
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FolderSelector;
